.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.accordionContent {
  margin: 0 0 30px 15px;
  padding-top: 0;
}

.accordionContent:before {
  background: linear-gradient(0deg, transparent 7px, #F29104 0, #F29104 100%);
  content: "";
  height: 100%;
  width: 2px;
  position: absolute;
}

/* .accordionWrap {
  margin: 0 0 0 15px;
  padding: 25px 0 0 6px;
  position: relative;
} */

.accordionInner h4 {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  cursor: pointer;
  padding-bottom: 0;
  position: relative;

}

.accordionInner h4:before {
  content: "";
  position: absolute;
  border-bottom: 2.5px solid #f29104;
  left: -21px;
  width: 15px;
  top: 7px;
}

.second-title {
  margin: 0 0 0 15px;
  padding: 25px 0 0 6px;
  position: relative;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

/* .yellowblock .et_pb_toggle_content:before */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
}
