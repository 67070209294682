@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add fonts by url /fonts/e-Ukraine-Regular.otf */
@font-face {
  font-family: 'Ukraine';
  font-style: normal;
  font-weight: 400;
  src: local('Ukraine'), local('Ukraine-Regular'),
    url('../public/fonts/e-Ukraine-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Ukraine';
  font-style: normal;
  font-weight: 300;
  src: local('Ukraine Light'), local('Ukraine-Light'),
    url('../public/fonts/e-Ukraine-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Ukraine';
  font-style: normal;
  font-weight: 500;
  src: local('Ukraine Medium'), local('Ukraine-Medium'),
    url('../public/fonts/e-Ukraine-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Ukraine';
  font-style: normal;
  font-weight: 700;
  src: local('Ukraine Bold'), local('Ukraine-Bold'),
    url('../public/fonts/e-Ukraine-Bold.otf') format('opentype');
}

/* Thin */
@font-face {
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 100;
  src: local('e-Ukraine Thin'), local('e-Ukraine-Thin'),
    url('../public/fonts/e-Ukraine-Thin.otf') format('opentype');
}

/* e-Ukraine-UltraLight */
@font-face {
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 200;
  src: local('e-Ukraine UltraLight'), local('e-Ukraine-UltraLight'),
    url('../public/fonts/e-Ukraine-UltraLight.otf') format('opentype');
}

/* и так далее для каждого варианта шрифта */

body {
  margin: 0;
  font-family: 'e-Ukraine', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body a {
  font-weight: 600;
  text-decoration-line: underline;
  color: #f29104;
  text-underline-position: under;
}

nav a {
  color: white;
  text-decoration: none;
}

a,
abbr,
acronym,
address,
applet,
b,
big,
blockquote,
body,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
tt,
u,
ul,
var {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #444a38;
  padding-bottom: 10px;
}

h2,
.product .related h2,
.et_pb_column_1_2 .et_quote_content blockquote p {
  font-size: 24px;
}

.useful-contacts h4 {
  margin-top: 20px;
}

h4,
.et_pb_circle_counter h3,
.et_pb_number_counter h3,
.et_pb_column_1_3 .et_pb_post h2,
.et_pb_column_1_4 .et_pb_post h2,
.et_pb_blog_grid h2,
.et_pb_column_1_3 .et_quote_content blockquote p,
.et_pb_column_3_8 .et_quote_content blockquote p,
.et_pb_column_1_4 .et_quote_content blockquote p,
.et_pb_blog_grid .et_quote_content blockquote p,
.et_pb_column_1_3 .et_link_content h2,
.et_pb_column_3_8 .et_link_content h2,
.et_pb_column_1_4 .et_link_content h2,
.et_pb_blog_grid .et_link_content h2,
.et_pb_column_1_3 .et_audio_content h2,
.et_pb_column_3_8 .et_audio_content h2,
.et_pb_column_1_4 .et_audio_content h2,
.et_pb_blog_grid .et_audio_content h2,
.et_pb_column_3_8 .et_pb_audio_module_content h2,
.et_pb_column_1_3 .et_pb_audio_module_content h2,
.et_pb_gallery_grid .et_pb_gallery_item h3,
.et_pb_portfolio_grid .et_pb_portfolio_item h2,
.et_pb_filterable_portfolio_grid .et_pb_portfolio_item h2 {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.et_quote_content blockquote p,
.et_pb_slide_description .et_pb_slide_title {
  line-height: 1.2em;
}

.rsc-cs {
  padding: 0 !important;
}

.rsc-ts-user .rsc-ts-bubble {
  /* bg opacity */
  background-color: rgb(77 70 52 / .7) !important;
}

h2.rsc-header-title {
  font-size: 1.5rem !important;
  color: #fff;
}